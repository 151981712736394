import React, { memo } from "react";
import PropTypes from "prop-types";
import { ResponsiveBar } from "@nivo/bar";

export const ShipmentsByWeekBarChart = memo(function ShipmentsByWeekBarChart_Memo({ data, indexBy, keys, colorBy }) {
  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy={indexBy}
      margin={{ top: 40, right: 10, bottom: 110, left: 30 }}
      padding={0.5}
      colors={{ scheme: "nivo" }}
      //colorBy={colorBy}
      borderColor={{ from: "color", modifiers: [["darker", "1.6"]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -54,
        legend: "", //"country",
        legendPosition: "middle",
        legendOffset: 32
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "", // "food",
        legendPosition: "middle",
        legendOffset: -40
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["darker", "1.6"]] }}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      legends={[
        /*
         *{
         *  dataFrom: "keys",
         *  anchor: "bottom-right",
         *  direction: "column",
         *  justify: false,
         *  translateX: 0,
         *  translateY: 0,
         *  itemsSpacing: 2,
         *  itemWidth: 100,
         *  itemHeight: 20,
         *  itemDirection: "left-to-right",
         *  itemOpacity: 0.85,
         *  symbolSize: 20,
         *  effects: [
         *    {
         *      on: "hover",
         *      style: {
         *        itemOpacity: 1
         *      }
         *    }
         *  ]
         *}
         */
      ]}
    />
  );
});

ShipmentsByWeekBarChart.propTypes = {
  data: PropTypes.object.isRequired,
  keys: PropTypes.array.isRequired,
  indexBy: PropTypes.string.isRequired,
  colorBy: PropTypes.func
};
