import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { useHistory, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import OrienAvatarAPI from "../../api";
import { STATE_KEY as USER_STATE_KEY } from "../../User";
import { AbilityContext, subjects, Can } from "../../../ability";
import axios from "axios";

export const Menu = () => {
  const [dnanexusSAML, setDNANexusSAML] = useState(null);
  const user = useSelector(state => state[USER_STATE_KEY]);
  const { hasinformatics, hascbioPortal, hasDnaNexus, hasORIENx, ORIENxToken, hasLimitedAccess, hasToolBox, apiKey, commonAuthId } = user;
  const ability = useContext(AbilityContext);
  const history = useHistory();

  const fetchDNANexusSAML = () => {
    OrienAvatarAPI.request({
      method: "post",
      url: `https://${__API__}/user/auth`,
      headers: {
        "X-Api-Key": apiKey
      },
      data: {
        commonAuthId: commonAuthId,
        serviceprovider: "dnanexus"
      }
    })
      .then(response => {
        if (response && response.data) {
          setDNANexusSAML(response.data);
        }
      })
      .catch(error => {
        console.log("Failed to fetch DNANexus SAML", error);
      });
  };

  useEffect(() => {
    if (!dnanexusSAML) {
      // && ability.can("view", subjects.APP_DNANEXUS)) {
      //console.log("About to fetchDNANexusSAML()");
      fetchDNANexusSAML();
    }
  },[dnanexusSAML]);

  const handleDNANexusClick = e => {
    e.preventDefault();
    document.forms["dnanexusForm"].submit();
  };

  const handleORIENxClick = e => {
    e.preventDefault();
    document.forms["orienxForm"].submit();
  };

  return (
    <nav>
      <Can I="view" a={subjects.APP_DNANEXUS}>
        <form method="post" action="https://auth.dnanexus.com/saml2/acs" name="dnanexusForm" target="_blank">
          <input type="hidden" name="SAMLResponse" value={dnanexusSAML || ""} />
        </form>
      </Can>
      <Can I="view" a={subjects.APP_ORIENX}>
        <form method="post" action={`https://${__ORIENXHOST__}/api/sso`} name="orienxForm" target="_blank">
          <input type="hidden" name="token" value={ORIENxToken || ""} />
        </form>
      </Can>
      <div className="MenuCtn">
        <Row className="Menu">
          <Col xs={12} md={12}>
            <Row className="links-container">
              <Col md={10} xs={12}>
                <ul className="inline-block">
                  <Can not I="view" a={subjects.PAGE_DASHBOARD}>
                    <li>
                      <Link to="/">
                        <i className="fa fa-home fa-md" />
                        &nbsp;Home
                      </Link>
                    </li>
                  </Can>
                  <Can I="view" a={subjects.APP_IMV2}>
                    <li>
                      <a href="/informatics/index.html" target="_blank" rel="opener">
                        <i className="fa fa-chart-pie fa-md" />
                        &nbsp;Informatics Modules <sup>v2</sup>
                      </a>
                    </li>
                  </Can>

                  <Can I="view" a={subjects.APP_ORIENX}>
                    <li>
                      <a href="#" onClick={handleORIENxClick}>
                        <i className="fa fa-chart-bar fa-md" />
                        &nbsp;ORIENx
                      </a>
                    </li>
                  </Can>

                  <Can not I="view" a={subjects.APP_DNANEXUS}>
                    <li>
                      <a href="https://platform.dnanexus.com/login" target="_blank">
                        <i className="fa fa-share-alt fa-md" />
                        &nbsp;Molecular Data Access
                      </a>
                    </li>
                  </Can>

                  {ability.can("view", subjects.APP_DNANEXUS) && dnanexusSAML && (
                    <li>
                      <a href="#" onClick={handleDNANexusClick}>
                        <i className="fa fa-share-alt fa-md" />
                        &nbsp;Molecular Data Access
                      </a>
                    </li>
                  )}

                  <Can I="view" a={subjects.APP_CBIOPORTAL}>
                    <li>
                      <Link to="/cBioPortal">
                        <i className="fa fa-chart-line fa-md" />
                        &nbsp;cbioPortal
                      </Link>
                    </li>
                  </Can>

                  <Can I="view" a={subjects.TOOLBOX}>
                    <li>
                      <Link to="/avatarID">
                        <i className="fa fa-wrench fa-md" />
                        &nbsp;Toolbox
                      </Link>
                    </li>
                  </Can>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <ReactTooltip />
    </nav>
  );
};
