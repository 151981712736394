/**
 * LandingContainer
 */

import React from "react";
import { ResearchCollaborationInfo, MolecularDataGenerationInfo } from "../components";

export const LandingContainer = () => {
  return (
    <div id="landing-container">
      <ResearchCollaborationInfo />
      <MolecularDataGenerationInfo />
    </div>
  );
};
