// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/researchers-dna.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/molecular-data-1.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "#landing-container{margin-bottom:2em}#landing-container section.info{padding:3em 3em;background-color:#ffffff}#landing-container section.info h2{text-align:left;margin-bottom:1em;color:#25205C}#landing-container section.info p{line-height:1.5}#landing-container section.info ul{margin-left:1em}#landing-container section.info .content-container{width:35em;max-width:60%;padding:2em;background-color:rgba(255,255,255,0.9);border-radius:8px;border:1px solid #DDDDDD;font-size:16px}#landing-container section.info.research-collaboration{display:-webkit-flex;display:flex;-webkit-justify-content:flex-start;justify-content:flex-start;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:left;background-repeat:no-repeat;background-size:cover;position:relative}#landing-container section.info.molecular-data-generation{display:-webkit-flex;display:flex;-webkit-justify-content:flex-end;justify-content:flex-end;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-position:left;background-repeat:no-repeat;background-size:cover;position:relative}\n", ""]);
// Exports
module.exports = exports;
