import React from "react";

export const ResearchCollaborationInfo = () => {
    return (
        <section className="info research-collaboration">
            <div className="content-container">
                <h2>Research Collaborations</h2>
                <p>
                    M2Gen provides research and infomatics tools to facilitate
                    collaboration, hypothesis generation, and cohort
                    identification to{" "}
                    <a
                        href="http://www.orientcc.org"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        ORIEN Total Cancer Care
                    </a>{" "}
                    (TCC) members through the Avatar program. All of the ORIEN
                    members are connected to enable collaborative research in
                    order to advance discovery in the prevention and cure of
                    cancer.
                </p>
            </div>
        </section>
    );
};
