/**
 * Chevron Component
 */

import React, { memo } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-flexbox-grid";
import styled from "styled-components";

export const Chevron = memo(function Chevron_Memo({ data }) {
  return (
    <>
      <Wrapper>
        <div>Potential Avatar Patients Clinical Data Progression</div>
        <List>
          {data.map((chevron, i) => (
            <ListItem key={i} index={i}>
              <InnerWrapper>
                <KPI>{chevron.value}</KPI>
                <Title>{chevron.title}</Title>
              </InnerWrapper>
            </ListItem>
          ))}
        </List>
      </Wrapper>
    </>
  );
});

const Wrapper = styled.div`
  display: table;
  margin: 0 auto;
  margin-top: 20px;
`;

const List = styled.ul`
  padding: 0;
  list-style-type: none;
  font-size: 1rem;
  text-align: center;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  left: 25px;
  width: 100px;
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  float: left;
  position: relative;
  width: 130px;
  height: 80px;
  background-color: ${({ index }) => (index % 2 === 0 ? "#ceb7dc" : "#6c2797")};
  color: ${({ index }) => (index % 2 === 0 ? "#000000" : "#ffffff")};
  margin-bottom: 1rem;

  &:after {
    content: "";
    border-left: 20px solid ${({ index }) => (index % 2 === 0 ? "#ceb7dc" : "#6c2797")};
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 20;
  }
`;

const KPI = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 40px;
  font-size: 2rem;
`;

const Title = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 40px;
  font-size: 1.2rem;
`;

Chevron.propTypes = {
  //onChevronClick: PropTypes.func.isRequired
  data: PropTypes.object.isRequired
};
