import * as actionTypes from "./actionTypes";

export const setDataTypeLoading = (dataType, loading) => {
  return {
    type: actionTypes.SET_DATATYPE_LOADING,
    value: { [dataType]: loading }
  };
};

export const setDataTypeLoaded = (dataType, loaded) => {
  return {
    type: actionTypes.SET_DATATYPE_LOADED,
    value: { [dataType]: loaded }
  };
};

export const setData = data => {
  return {
    type: actionTypes.SET_DATA,
    value: data
  };
};
