import React, { useEffect, useMemo, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { STATE_KEY as DASHBOARD_STATE_KEY } from "../../Dashboard";
import { STATE_KEY as USER_STATE_KEY } from "../../User";
import { STATE_KEY as SUMMARY_BOXES_STATE_KEY } from "../../SummaryBoxes";
import { KPIs, Overview, CurrentAvatarPatientCountLineChart, ShipmentsByWeekBarChart, ShipmentsByWeekGrid, PatientsOverTimeLineChart } from "./";
import ContentLoader from "react-content-loader";
import * as actions from "../actions";
import axios from "axios";
import styled from "styled-components";
import moment from "moment";
import * as Utils from "../../../data/utilities";
import { AbilityContext, Can, subjects } from "../../../ability";

export const Dashboard = () => {
  //console.log("inside of Dashboard");
  const dispatch = useDispatch();
  const user = useSelector(state => state[USER_STATE_KEY]);
  const dashboardSelector = useSelector(state => state[DASHBOARD_STATE_KEY]);
  const summaryBoxesSelector = useSelector(state => state[SUMMARY_BOXES_STATE_KEY]);
  const ability = useContext(AbilityContext);

  // Adds commas as a thousands separator
  const formatNumber = num => {
    //console.log("formatNumber", num);
    return !!num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : null;
  };

  // Preparing data for charts ---------------------------------

  const kpiData = useMemo(() => {
    let data = [];

    // if the user is ORIEN
    if (ability.can("view", subjects.ROLE_ORIEN)) {
      let siteSpecificPatientsWithSpecimenShipped = "";
      const foundSite = dashboardSelector.weeklySpecimenShipmentsBySite.find(({ site }) => site.toLowerCase() == user.company.toLowerCase());
      if (foundSite) {
        siteSpecificPatientsWithSpecimenShipped = formatNumber(foundSite.sitetotalspecimensshipped);
      }

      data.push(
        { title: "Site Specific Released Avatar Patients", value: formatNumber(summaryBoxesSelector.counts.siteAvatarCounts), color: "#35a093" },
        { title: "Site Specific Patients with Specimens Shipped", value: formatNumber(siteSpecificPatientsWithSpecimenShipped) },
        { title: "Total Released Avatar Patients Across the Network", value: formatNumber(summaryBoxesSelector.counts.networkAvatarCounts) },
        { title: "Total Patients with Specimens Shipped Across the Network", value: formatNumber(dashboardSelector.totalSpecimensShipped) },
        { title: "Current Network Goal (Released Avatar Patients)", value: formatNumber(dashboardSelector.patientGoal.patientGoal) }
      );
    }

    // if the user is M2GEN
    if (ability.can("view", subjects.ROLE_M2GEN)) {
      data.push({ title: "Current Avatar Patient Count", value: formatNumber(summaryBoxesSelector.counts.siteAvatarCounts), color: "#35a093" }, { title: "Current Specimen Count", value: formatNumber(dashboardSelector.totalSpecimensShipped) }, { title: "Current Network Goal", value: formatNumber(dashboardSelector.patientGoal.patientGoal) });
    }

    return data;
  }, [summaryBoxesSelector, dashboardSelector]);

  const currentAvatarPatientCount = formatNumber(summaryBoxesSelector.counts.siteAvatarCounts);

  const avatarReleasedDataReduce = useMemo(
    () =>
      dashboardSelector.patientsOverTime.AvatarReleased.PatientsOverTime.reduce((accumulator, currentValue) => {
        const currentDate = moment(currentValue.DataDate);
        const currentKey = currentDate.format("MMM YYYY"); // Eg. "Dec 2019"
        const currentCount = currentValue.PatientCount || 0;
        const newCount = accumulator[currentKey] ? accumulator[currentKey] + currentCount : currentCount;

        return {
          ...accumulator,
          [currentKey]: newCount
        };
      }, {}),
    [dashboardSelector.patientsOverTime.AvatarReleased]
  );

  //console.log("dashboardSelector.weeklySpecimenShipments", dashboardSelector.weeklySpecimenShipments);

  const currentAvatarPatientCountLineChartData = useMemo(() => {
    let cumulativeTotal = 0;
    const patientGoal = dashboardSelector.patientGoal.patientGoal;

    const avatarReleasedDataReduceKeys = Object.keys(avatarReleasedDataReduce);
    const firstKey = avatarReleasedDataReduceKeys[0];
    const lastKey = avatarReleasedDataReduceKeys[avatarReleasedDataReduceKeys.length - 1];

    let chartData = [
      {
        id: "Avatar Released",
        data: avatarReleasedDataReduceKeys.map(key => {
          cumulativeTotal += avatarReleasedDataReduce[key];

          return {
            x: key,
            y: cumulativeTotal
          };
        })
      }
    ];

    // M2GEN role should also see the current network goal
    if (ability.can("view", subjects.ROLE_M2GEN)) {
      const currentNetworkGoal = {
        id: "Current Network Goal",
        data: [
          {
            x: firstKey,
            y: patientGoal
          },
          {
            x: lastKey,
            y: patientGoal
          }
        ]
      };

      // Add the current goal plot to the graph
      chartData.push(currentNetworkGoal);
    }

    return chartData;
  }, [avatarReleasedDataReduce, dashboardSelector.patientGoal]);

  const shipmentsByWeekBarChartIndexBy = "Shipment Date";
  const shipmentsByWeekBarChartColorBy = shipment => shipment.data.year;
  const earliestDateToPlot = moment()
    .subtract(52, "weeks")
    .startOf("day");
  let shipmentsByWeekBarChartKeys = useMemo(() => {
    let keys = [];
    dashboardSelector.weeklySpecimenShipments.map(shipment => {
      const shipmentDate = moment(shipment.shipmentweek).format("MM/DD/YYYY");
      // only add the date if it's within the last 52 weeks
      if (!keys.find(key => key == shipmentDate) && moment(shipmentDate, "MM/DD/YYYY").isSameOrAfter(moment(earliestDateToPlot, "MM/DD/YYYY"))) {
        keys.push(shipmentDate);
      }
    });

    // sort shipments with the newest first
    const sortedKeys = keys.sort((a, b) => moment(b.shipmentweek, "MM/DD/YYYY") - moment(a.shipmentweek, "MM/DD/YYYY"));

    return sortedKeys;
  }, [dashboardSelector.weeklySpecimenShipments]);

  const shipmentsByWeekBarChartData = useMemo(() => {
    let data = [];
    // sort shipments with the newest first
    const sortedWeeklySpecimenShipments = dashboardSelector.weeklySpecimenShipments.sort((a, b) => moment(b.shipmentweek) - moment(a.shipmentweek));

    sortedWeeklySpecimenShipments.map(shipment => {
      const shipmentDate = moment(shipment.shipmentweek).format("MM/DD/YYYY");
      // if this date is in the shipmentsByWeekBarChartKeys then add it.
      if (shipmentsByWeekBarChartKeys.find(key => key == shipmentDate)) {
        const year = moment(shipment.shipmentweek).format("YYYY");
        data.push({
          [shipmentsByWeekBarChartIndexBy]: shipmentDate,
          [shipmentDate]: shipment.specimensshipped,
          year
        });
      }
    });


    return data;
  }, [dashboardSelector.weeklySpecimenShipments, shipmentsByWeekBarChartKeys]);

  const { shipmentsByWeekGridColumns, shipmentsByWeekGridData } = useMemo(() => {
    let dateKeys = [];
    let data = [];

    // loop through all sites
    dashboardSelector.weeklySpecimenShipmentsBySite.map(site => {
      let siteRowData = {};
      siteRowData.id = site.site;
      siteRowData.site = site.site;
      siteRowData.total = site.sitetotalspecimensshipped;

      // loop through all weekly shipments for a site
      site.weeklyshipments.map(shipment => {
        const shipmentDate = moment(shipment.shipmentweek).format("MM/DD/YYYY");

        // if this date doesn't exist in the date keys then add it.
        if (!dateKeys.find(d => d === shipmentDate)) {
          dateKeys.push(shipmentDate);
        }

        // Now add the row data
        siteRowData[shipmentDate] = shipment.specimensshipped;
      });

      data.push(siteRowData);
    });

    let columns = [
      { title: "Member Site", key: "site", dataKey: "site", width: 140, frozen: "left", resizable: true, flexGrow: 2 },
      { title: "Totals", key: "total", dataKey: "total", width: 100, resizable: true, flexGrow: 1 }
    ];
    // sort shipments with the newest first
    dateKeys.sort((a, b) => moment(b) - moment(a));
    dateKeys.map(date => {
      columns.push({
        title: date,
        key: date,
        dataKey: date,
        width: 100,
        resizable: true,
        flexGrow: 1
      });
    });

    return {
      shipmentsByWeekGridColumns: columns,
      shipmentsByWeekGridData: data
    };
  }, [dashboardSelector.weeklySpecimenShipmentsBySite]);

  // 2020-06-10 Dan requested that we remove the Patients Over Time graph per Janni's request
  // which is due to discrepancies over whether a specimen should be counted with the earliest
  // extraction date (between these: Shipped, Extracted, Clinical Received and Avatar Released)
  // or 3 separate times.
  /*
   *  const patientsOverTimeInitialDates = useMemo(() => {
   *    const { AvatarReleased, ClinReceived, Extracted, Shipped } = dashboardSelector.patientsOverTime;
   *    let dateKeys = [];
   *
   *    AvatarReleased.PatientsOverTime.map(p => {
   *      const formattedDate = moment(p.DataDate).format("MMM YYYY"); // Eg. "Dec 2019"
   *      // if this date doesn't exist in the date keys then add it.
   *      if (!dateKeys.find(d => d.DataDate === formattedDate)) {
   *        dateKeys.push({ DataDate: formattedDate, PatientCount: 0 });
   *      }
   *    });
   *    ClinReceived.PatientsOverTime.map(p => {
   *      const formattedDate = moment(p.DataDate).format("MMM YYYY"); // Eg. "Dec 2019"
   *      // if this date doesn't exist in the date keys then add it.
   *      if (!dateKeys.find(d => d.DataDate === formattedDate)) {
   *        dateKeys.push({ DataDate: formattedDate, PatientCount: 0 });
   *      }
   *    });
   *    Extracted.PatientsOverTime.map(p => {
   *      const formattedDate = moment(p.DataDate).format("MMM YYYY"); // Eg. "Dec 2019"
   *      // if this date doesn't exist in the date keys then add it.
   *      if (!dateKeys.find(d => d.DataDate === formattedDate)) {
   *        dateKeys.push({ DataDate: formattedDate, PatientCount: 0 });
   *      }
   *    });
   *    Shipped.PatientsOverTime.map(p => {
   *      const formattedDate = moment(p.DataDate).format("MMM YYYY"); // Eg. "Dec 2019"
   *      // if this date doesn't exist in the date keys then add it.
   *      if (!dateKeys.find(d => d.DataDate === formattedDate)) {
   *        dateKeys.push({ DataDate: formattedDate, PatientCount: 0 });
   *      }
   *    });
   *
   *    return dateKeys;
   *  }, [dashboardSelector.patientsOverTime]);
   *  const patientsOverTimeReduceHelper = patientsOverTimeArray => {
   *    //console.log("patientsOverTimeArray", patientsOverTimeArray);
   *    return patientsOverTimeArray.reduce((accumulator, currentValue) => {
   *      const currentDate = moment(currentValue.DataDate, "YYYY-MM-DDT00:00:00.000Z");
   *      const currentKey = currentDate.format("MMM YYYY"); // Eg. "Dec 2019"
   *      const currentCount = currentValue.PatientCount || 0;
   *      const newCount = accumulator[currentKey] ? accumulator[currentKey] + currentCount : currentCount;
   *
   *      //console.log("currentKey, currentCount, newCount", currentKey, currentCount, newCount);
   *
   *      return {
   *        ...accumulator,
   *        [currentKey]: newCount
   *      };
   *    }, {});
   *  };
   *
   *  const patientsOverTimeLineChartData = useMemo(() => {
   *    let cumulativeTotal = {
   *      avatarReleased: 0,
   *      clinReceived: 0,
   *      extracted: 0,
   *      shipped: 0
   *    };
   *    const { AvatarReleased, ClinReceived, Extracted, Shipped } = dashboardSelector.patientsOverTime;
   *
   *    // Reduce
   *    const avatarReleasedReduce = patientsOverTimeReduceHelper([...patientsOverTimeInitialDates, ...AvatarReleased.PatientsOverTime]);
   *    const clinReceivedReduce = patientsOverTimeReduceHelper([...patientsOverTimeInitialDates, ...ClinReceived.PatientsOverTime]);
   *    const extractedReduce = patientsOverTimeReduceHelper([...patientsOverTimeInitialDates, ...Extracted.PatientsOverTime]);
   *    const shippedReduce = patientsOverTimeReduceHelper([...patientsOverTimeInitialDates, ...Shipped.PatientsOverTime]);
   *
   *    // Sort date array
   *    patientsOverTimeInitialDates.sort((a, b) => moment(a.DataDate, "MMM YYYY") - moment(b.DataDate, "MMM YYYY"));
   *
   *    return [
   *      {
   *        id: "Avatar Released",
   *        data: patientsOverTimeInitialDates.map(p => {
   *          const key = p.DataDate;
   *          cumulativeTotal.avatarReleased += avatarReleasedReduce[key] || 0;
   *
   *          return {
   *            x: key,
   *            y: cumulativeTotal.avatarReleased
   *          };
   *        })
   *      },
   *      {
   *        id: "Clinical Received",
   *        data: patientsOverTimeInitialDates.map(p => {
   *          const key = p.DataDate;
   *          cumulativeTotal.clinReceived += clinReceivedReduce[key] || 0;
   *
   *          return {
   *            x: key,
   *            y: cumulativeTotal.clinReceived
   *          };
   *        })
   *      },
   *      {
   *        id: "Extracted",
   *        data: patientsOverTimeInitialDates.map(p => {
   *          const key = p.DataDate;
   *          cumulativeTotal.extracted += extractedReduce[key] || 0;
   *
   *          return {
   *            x: key,
   *            y: cumulativeTotal.extracted
   *          };
   *        })
   *      },
   *      {
   *        id: "Shipped",
   *        data: patientsOverTimeInitialDates.map(p => {
   *          const key = p.DataDate;
   *          cumulativeTotal.shipped += shippedReduce[key] || 0;
   *
   *          return {
   *            x: key,
   *            y: cumulativeTotal.shipped
   *          };
   *        })
   *      }
   *    ];
   *  }, [dashboardSelector.patientsOverTime]);
   */

  const { PatientsWithShippedSpecimens, MolecularExtractedSent, MolecularSequenced, MolecularPassedQC, MolecularValidated, ClinicalReceived, ClinicalPassed, ClinicalValidated } = dashboardSelector.avatarPatientsPipeline;

  // Fetch all the data ---------------------------------
  const axiosInstance = axios.create({
    baseURL: `https://${__GENERICAPI__}/orienavatar/${user.userType}/${user.company}/dashboard`,
    headers: {
      Authorization: user.apiKey
    }
  });

  const fetchSummaryDataHelper = () => {
    return new Promise((resolve, reject) => {
      //console.log("Promise for fetchSummaryDataHelper");
      Utils.fetchSummaryData(axiosInstance, [], resolve, reject);
    });
  };

  useEffect(() => {
    // if the data has already been loaded then exit out of this effect.
    const { summary } = dashboardSelector.loaded;
    if (summary) {
      return;
    }

    const startingPageIndex = 0;

    // fetch summary information
    dispatch(actions.setDataTypeLoading("summary", true));
    dispatch(actions.setDataTypeLoaded("summary", false));
    fetchSummaryDataHelper().then(response => {
      const { avatarpatientspipeline, fiscalyear, patientgoal, patientsovertime, weeklyspecimenshipments, totalspecimensshipped, weeklyspecimenshipmentsbysite, summary } = response;
      const newData = {
        summary: summary,
        avatarPatientsPipeline: avatarpatientspipeline,
        patientsOverTime: patientsovertime,
        weeklySpecimenShipments: weeklyspecimenshipments,
        totalSpecimensShipped: totalspecimensshipped,
        weeklySpecimenShipmentsBySite: weeklyspecimenshipmentsbysite,
        patientGoal: {
          fiscalYear: fiscalyear,
          patientGoal: patientgoal
        }
      };
      dispatch(actions.setData(newData));
      dispatch(actions.setDataTypeLoading("summary", false));
      dispatch(actions.setDataTypeLoaded("summary", true));
    });
  }, []);

  return (
    <>
      <ChartingGrid>
        {(ability.can("view", subjects.ROLE_ORIEN) || ability.can("view", subjects.ROLE_M2GEN)) && (
          <>
            {!dashboardSelector.loaded.summary ? (
              <SkeletonContentKPI />
            ) : (
              <ChartWrapper>
                <>
                  {ability.can("view", subjects.ROLE_ORIEN) && <ChartTitle>Member Patient Avatar and Overall Network Counts</ChartTitle>}
                  {ability.can("view", subjects.ROLE_M2GEN) && <ChartTitle>Current Overall Network Counts</ChartTitle>}
                  <KPIs data={kpiData} />
                </>
              </ChartWrapper>
            )}
          </>
        )}
        {(ability.can("view", subjects.ROLE_PHARMA) || ability.can("view", subjects.ROLE_M2GEN)) && (
          <>
            {!dashboardSelector.loaded.summary ? (
              <SkeletonContentKPI />
            ) : (
              <ChartWrapper>
                <ChartTitle>Network Avatar Patient Progression</ChartTitle>
                <Overview patientsWithShippedSpecimens={PatientsWithShippedSpecimens} molecularExtractedSent={MolecularExtractedSent} molecularSequenced={MolecularSequenced} molecularPassedQC={MolecularPassedQC} molecularValidated={MolecularValidated} clinicalReceived={ClinicalReceived} clinicalPassedQC={ClinicalPassed} clinicalValidated={ClinicalValidated} totalAvatarPatients={currentAvatarPatientCount} />
              </ChartWrapper>
            )}
          </>
        )}
      </ChartingGrid>
      {ability.can("view", subjects.ROLE_M2GEN) && (
        <>
          {!dashboardSelector.loaded.summary ? (
            <SkeletonContentLineChart />
          ) : (
            <ChartFullWidthWrapper>
              <ChartTitle>Current Avatar Patient Count vs Current Network Goal - All Members</ChartTitle>
              <CurrentAvatarPatientCountLineChart data={currentAvatarPatientCountLineChartData} />
            </ChartFullWidthWrapper>
          )}
        </>
      )}
      {ability.can("view", subjects.ROLE_ORIEN) && (
        <>
          {!dashboardSelector.loaded.summary ? (
            <SkeletonContentLineChart />
          ) : (
            <ChartFullWidthWrapper>
              <ChartTitle>Current Avatar Patient Count</ChartTitle>
              <CurrentAvatarPatientCountLineChart data={currentAvatarPatientCountLineChartData} />
            </ChartFullWidthWrapper>
          )}
        </>
      )}
      {(ability.can("view", subjects.ROLE_M2GEN) || ability.can("view", subjects.ROLE_ORIEN)) && (
        <>
          {!dashboardSelector.loaded.summary ? (
            <SkeletonContentBarChart />
          ) : (
            <ChartFullWidthWrapper>
              <ChartTitle>Shipments by Week - All Members</ChartTitle>
              <ShipmentsByWeekBarChart data={shipmentsByWeekBarChartData} keys={shipmentsByWeekBarChartKeys} indexBy={shipmentsByWeekBarChartIndexBy} colorBy={shipmentsByWeekBarChartColorBy} />
            </ChartFullWidthWrapper>
          )}
        </>
      )}
      {(ability.can("view", subjects.ROLE_ORIEN) || ability.can("view", subjects.ROLE_M2GEN)) && (
        <>
          {!dashboardSelector.loaded.summary ? (
            <SkeletonContentTable />
          ) : (
            <ChartFullWidthWrapper height={`${shipmentsByWeekGridData.length * 50 + 100}px`}>
              <ChartTitle>Shipments by Week</ChartTitle>
              <TableWrapper>
                <ShipmentsByWeekGrid columns={shipmentsByWeekGridColumns} data={shipmentsByWeekGridData} />
              </TableWrapper>
            </ChartFullWidthWrapper>
          )}
        </>
      )}
      {
        // 2020-06-10 Dan requested that we remove the Patients Over Time graph per Janni's request
        // which is due to discrepancies over whether a specimen should be counted with the earliest
        // extraction date (between these: Shipped, Extracted, Clinical Received and Avatar Released)
        // or 3 separate times.
        /*
         *{(ability.can("view", subjects.ROLE_PHARMA) || ability.can("view", subjects.ROLE_M2GEN)) && (
         *  <>
         *  {!dashboardSelector.loaded.summary ? (
         *    <SkeletonContentLineChart />
         *  ) : (
         *    <ChartFullWidthWrapper>
         *    <ChartTitle>Patients Over Time</ChartTitle>
         *    <PatientsOverTimeLineChart data={patientsOverTimeLineChartData} />
         *    </ChartFullWidthWrapper>
         *  )}
         *  </>
         *)}
         */
      }
    </>
  );
};

const SkeletonContentLineChart = () => {
  return (
    <ContentLoader width={1000} viewBox="0 0 1000, 150">
      <rect x="0" y="0" rx="5" ry="5" width="800" height="100" />
    </ContentLoader>
  );
};

const SkeletonContentKPI = () => {
  return (
    <ContentLoader width={500} viewBox="0 0 500, 200">
      <rect x="0" y="0" rx="5" ry="5" width="400" height="30" />
      <rect x="40" y="60" rx="0" ry="0" width="300" height="15" />
      <rect x="40" y="90" rx="0" ry="0" width="300" height="15" />
      <rect x="40" y="120" rx="0" ry="0" width="300" height="15" />
    </ContentLoader>
  );
};

const SkeletonContentBarChart = () => {
  return (
    <ContentLoader width={1200} viewBox="0 0 1000, 200">
      <rect x="20" y="5" rx="0" ry="0" width="1" height="170" />
      <rect x="20" y="175" rx="0" ry="0" width="360" height="1" />

      <rect x="40" y="75" rx="0" ry="0" width="35" height="100" />
      <rect x="80" y="125" rx="0" ry="0" width="35" height="50" />
      <rect x="120" y="105" rx="0" ry="0" width="35" height="70" />
      <rect x="160" y="35" rx="0" ry="0" width="35" height="140" />
      <rect x="200" y="55" rx="0" ry="0" width="35" height="120" />
      <rect x="240" y="15" rx="0" ry="0" width="35" height="160" />
      <rect x="280" y="135" rx="0" ry="0" width="35" height="40" />
      <rect x="320" y="85" rx="0" ry="0" width="35" height="90" />
    </ContentLoader>
  );
};

const SkeletonContentTable = () => {
  return (
    <ContentLoader width={800} viewBox="0 0 1500 500">
      <rect x="27" y="139" rx="4" ry="4" width="20" height="20" />
      <rect x="67" y="140" rx="10" ry="10" width="85" height="19" />
      <rect x="188" y="141" rx="10" ry="10" width="169" height="19" />
      <rect x="402" y="140" rx="10" ry="10" width="85" height="19" />
      <rect x="523" y="141" rx="10" ry="10" width="169" height="19" />
      <rect x="731" y="139" rx="10" ry="10" width="85" height="19" />
      <rect x="852" y="138" rx="10" ry="10" width="85" height="19" />
      <rect x="1424" y="137" rx="10" ry="10" width="68" height="19" />
      <rect x="26" y="196" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="197" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="198" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="197" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="198" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="196" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="195" rx="10" ry="10" width="85" height="19" />
      <circle cx="1456" cy="203" r="12" />
      <rect x="26" y="258" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="259" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="260" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="259" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="260" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="258" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="257" rx="10" ry="10" width="85" height="19" />
      <circle cx="1456" cy="265" r="12" />
      <rect x="26" y="316" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="317" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="318" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="317" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="318" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="316" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="315" rx="10" ry="10" width="85" height="19" />
      <circle cx="1456" cy="323" r="12" />
      <rect x="26" y="379" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="380" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="381" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="380" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="381" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="379" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="378" rx="10" ry="10" width="85" height="19" />
      <circle cx="1456" cy="386" r="12" />
      <rect x="978" y="138" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="195" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="257" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="315" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="378" rx="10" ry="10" width="169" height="19" />
      <rect x="1183" y="139" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="196" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="258" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="316" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="379" rx="10" ry="10" width="85" height="19" />
      <rect x="1305" y="137" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="194" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="256" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="314" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="377" rx="10" ry="10" width="85" height="19" />
      <circle cx="37" cy="97" r="11" />
      <rect x="26" y="23" rx="5" ry="5" width="400" height="30" />
      <circle cx="1316" cy="88" r="11" />
      <rect x="1337" y="94" rx="0" ry="0" width="134" height="3" />
      <circle cx="77" cy="96" r="11" />
    </ContentLoader>
  );
};

const ChartingGrid = styled.div`
  margin-top: 40px;
  display: grid;
  //width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr)); //repeat(2, 1fr);
  grid-template-rows: auto;
  grid-row-gap: 80px;
  grid-column-gap: 40px;
  padding: 20px;
`;

const ChartWrapper = styled.div`
  //height: ${({ height }) => height || "400px"};
  padding-bottom: 30px;
  margin-bottom: 10px;
`;

const ChartFullWidthWrapper = styled.div`
  width: 100%;
  height: ${({ height }) => height || "500px"};
  margin-top: 80px;
  padding-bottom: 30px;
  margin-bottom: 10px;
`;

const ChartTitle = styled.h3``;

const TableWrapper = styled.div`
  margin-top: 20px;
  height: ${({ height }) => height || `calc(100% - 50px)`};

  .BaseTable {
    &__header-cell {
      background-color: #1a89c2;
      color: #ffffff;
      font-weight: 500;
    }

    &__row:nth-of-type(odd) {
      background-color: #f6f6f6 !important;
    }

    &__row-cell,
    &__header-cell {
      border: 1px solid #eeeeee;
      justify-content: center;
    }
  }
`;
