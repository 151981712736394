import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Chevron, ClinicalDataByDiseaseGrid, ClinicalDataExpectedVsReceivedGrid, ClinicalDataByDiseasePie, STATE_KEY as CLINICAL_STATE_KEY } from "../../Clinical";
import { STATE_KEY as USER_STATE_KEY } from "../../User";
import ContentLoader from "react-content-loader";
import * as actions from "../actions";
import axios from "axios";
import * as Utils from "../../../data/utilities";
import styled from "styled-components";
import { AbilityContext, Can, subjects } from "../../../ability";

export const Clinical = () => {
  //console.log("inside of Clinical");
  const dispatch = useDispatch();
  const user = useSelector(state => state[USER_STATE_KEY]);
  const clinicalSelector = useSelector(state => state[CLINICAL_STATE_KEY]);
  const summaryData = clinicalSelector.summary;
  const totalsData = clinicalSelector.totals;
  const diseasesData = clinicalSelector.diseases;
  const clinicalDataReceivedData = clinicalSelector.clinicaldatareceived;
  //const diseaseTotalsData = clinicalSelector.summary.totals.Diseases;

  // Prepare data for charts ----------------------------
  const clinicalChevronData = useMemo(
    () => [
      { title: "Clinical Received", value: summaryData.ClinicalReceived },
      { title: "Pending Validation", value: summaryData.PendingValidation },
      { title: "Ready for Release", value: summaryData.AvailableForRelease }
    ],
    [summaryData]
  );

  const clinicalDiseaseColumns = useMemo(
    () => [
      { title: "Cancer Group", key: "CancerGroup", dataKey: "CancerGroup", width: 100, resizable: true , flexGrow: 1 },
      { title: "Cancer Type", key: "CancerType", dataKey: "CancerType", width: 200, resizable: true , flexGrow: 2 },
      { title: "Patient Count", key: "PatientCount", dataKey: "PatientCount", width: 100, resizable: true , flexGrow: 1 }
    ],
    []
  );
  const clinicalDiseaseData = useMemo(() => diseasesData, [diseasesData]);

  const clinicalDataExpectedVsReceivedColumns = useMemo(
    () => [
      { title: "Member Site", key: "MemberSite", dataKey: "MemberSite", width: 210.14, resizeable: true , flexGrow: 1 },
      { title: "Specimens Shipped", key: "SpecimensShipped", dataKey: "SpecimensShipped", width: 210.14, resizeable: true , flexGrow: 1 },
      { title: "Clinical Data Received", key: "ClinicalDataReceived", dataKey: "ClinicalDataReceived", width: 230.14, resizable: true , flexGrow: 2 },
      { title: "< 30", key: "Under30", dataKey: "Under30", width: 139.14, resizeable: true , flexGrow: 1 },
      { title: "30 < 60", key: "Between30and60", dataKey: "Between30and60", width: 139.14, resizbale: true , flexGrow: 1 },
      { title: "60 < 90", key: "Between60and90", dataKey: "Between60and90", width: 139.14, resiable: true , flexGrow: 1 },
      { title: "> 90", key: "Over90", dataKey: "Over90", width: 139.14, resizable: true , flexGrow: 1 }
    ],
    []
  );
  const clinicalDataExpectedVsReceivedData = useMemo(() => clinicalDataReceivedData, [clinicalDataReceivedData]);

  const clinicalDiseaseTotalsData = useMemo(
    () =>
      diseasesData.map(d => {
        return {
          id: d.CancerType,
          label: d.CancerType,
          value: d.PatientCount,
          percent: d.Percent
        };
      }),
    [diseasesData]
  );
  /*
   *const clinicalDiseaseTotalsData = totalsData.Diseases
   *    ? totalsData.Diseases.map(d => {
   *          return {
   *              id: d.DiseaseType,
   *              label: d.DiseaseType,
   *              value: d.total,
   *              percent: d.percent
   *          };
   *      })
   *    : [];
   */

  // Fetch all the data ---------------------------------
  const axiosInstance = axios.create({
    baseURL: `https://${__GENERICAPI__}/orienavatar/${user.userType}/${user.company}/clinical`,
    headers: {
      Authorization: user.apiKey
    }
  });

  const fetchSummaryDataHelper = () => {
    return new Promise((resolve, reject) => {
      //console.log("Promise for fetchSummaryDataHelper");
      Utils.fetchSummaryData(axiosInstance, [], resolve, reject);
    });
  };

  const fetchDetailDataHelper = (dataType, maxPageIndex, startingPageIndex, initialData) => {
    return new Promise((resolve, reject) => {
      //console.log("fetchDetailDataHelper", dataType, maxPageIndex, startingPageIndex);
      Utils.fetchPaginatedDetailData({
        axiosInstance,
        dataType,
        initialData,
        maxPageIndex,
        currentPageIndex: startingPageIndex,
        resolve,
        reject
      });
    });
  };

  useEffect(() => {
    // if the data has already been loaded then exit out of this effect.
    const { summary, patient, disease } = clinicalSelector.loaded;
    if (summary && patient && disease) {
      return; // exit out of this effect
    }

    const startingPageIndex = 0;

    // fetch summary information
    dispatch(actions.setDataTypeLoading("summary", true));
    dispatch(actions.setDataTypeLoaded("summary", false));
    fetchSummaryDataHelper().then(response => {
      const { summary, totals, patientpagecount: patientPageCount = 0, diseasepagecount: diseasePageCount = 0, releasespagecount: releasesPageCount = 0, clinicaldatareceived } = response;
      //console.log("response", response);
      //console.log("clinicaldatareceived", clinicaldatareceived);
      dispatch(actions.setSummaryData(summary));
      dispatch(actions.setDataTypeLoading("summary", false));
      dispatch(actions.setDataTypeLoaded("summary", true));

      dispatch(actions.setClinicalDataReceivedData(clinicaldatareceived));
      dispatch(actions.setDataTypeLoading("clinicaldatareceived", false));
      dispatch(actions.setDataTypeLoaded("clinicaldatareceived", true));

      dispatch(actions.setTotalsData(totals));
      dispatch(actions.setDataTypeLoading("totals", false));
      dispatch(actions.setDataTypeLoaded("totals", true));

      // fetch Patient Details
      dispatch(actions.setDataTypeLoading("patient", true));
      dispatch(actions.setDataTypeLoaded("patient", false));
      fetchDetailDataHelper("patient", patientPageCount - 1, startingPageIndex, []).then(response => {
        //console.log(`${Utils.DATATYPE.PATIENT} - ${response.length} records`);
        dispatch(actions.setPatientData(response));
        dispatch(actions.setDataTypeLoading("patient", false));
        dispatch(actions.setDataTypeLoaded("patient", true));
      });

      // fetch Disease Details
      dispatch(actions.setDataTypeLoading("disease", true));
      dispatch(actions.setDataTypeLoaded("disease", false));
      fetchDetailDataHelper("disease", diseasePageCount - 1, startingPageIndex, []).then(response => {
        //console.log(`${Utils.DATATYPE.DISEASE} - ${response.length} records`);
        dispatch(actions.setDiseaseData(response));
        dispatch(actions.setDataTypeLoading("disease", false));
        dispatch(actions.setDataTypeLoaded("disease", true));
      });
    });
  }, []); // only run this effect once

  return (
    <>
      {!clinicalSelector.loaded.summary ? <SkeletonContentChevron /> : <Chevron data={clinicalChevronData} />}
      <ChartingGrid>
        {!clinicalSelector.loaded.disease ? (
          <SkeletonContentTable />
        ) : (
          <ChartWrapper>
            <h3>Clinical Data By Disease</h3>
            <DataByDiseaseTableWrapper>
              <ClinicalDataByDiseaseGrid columns={clinicalDiseaseColumns} data={clinicalDiseaseData} />
            </DataByDiseaseTableWrapper>
          </ChartWrapper>
        )}
        <Can I="view" a={subjects.ROLE_ORIEN}>
          {!clinicalSelector.loaded.clinicaldatareceived ? (
            <SkeletonContentTable />
          ) : (
            <ChartWrapper style={{ height: "140px" }}>
              <h3>Clinical Data Expected vs Received (In Days)</h3>
              <DataExpectedVsReceivedTableWrapper>
                <ClinicalDataExpectedVsReceivedGrid columns={clinicalDataExpectedVsReceivedColumns} data={clinicalDataExpectedVsReceivedData} />
              </DataExpectedVsReceivedTableWrapper>
            </ChartWrapper>
          )}
        </Can>
        {!clinicalSelector.loaded.totals ? (
          <SkeletonContentPieChart />
        ) : (
          <ChartWrapper>
            <h3>Clinical Data By Disease</h3>
            <ClinicalDataByDiseasePie data={clinicalDiseaseTotalsData} />
          </ChartWrapper>
        )}
      </ChartingGrid>
    </>
  );
};

const SkeletonContentChevron = () => {
  return (
    <ContentLoader viewBox="0 0 2000, 350">
      <rect x="0" y="0" rx="0" ry="0" width="400" height="30" />
      <rect x="0" y="60" rx="5" ry="5" width="200" height="100" />
      <rect x="210" y="60" rx="5" ry="5" width="200" height="100" />
      <rect x="420" y="60" rx="5" ry="5" width="200" height="100" />
      <rect x="0" y="180" rx="5" ry="5" width="250" height="50" />
      <rect x="280" y="180" rx="5" ry="5" width="250" height="50" />
    </ContentLoader>
  );
};

const SkeletonContentPieChart = () => {
  return (
    <ContentLoader viewBox="0 0 1000, 600">
      <rect x="0" y="0" rx="0" ry="0" width="400" height="30" />
      <circle cx="300" cy="300" r="230" />
    </ContentLoader>
  );
};

const SkeletonContentTable = () => {
  return (
    <ContentLoader viewBox="0 0 1500 400">
      <rect x="27" y="139" rx="4" ry="4" width="20" height="20" />
      <rect x="67" y="140" rx="10" ry="10" width="85" height="19" />
      <rect x="188" y="141" rx="10" ry="10" width="169" height="19" />
      <rect x="402" y="140" rx="10" ry="10" width="85" height="19" />
      <rect x="523" y="141" rx="10" ry="10" width="169" height="19" />
      <rect x="731" y="139" rx="10" ry="10" width="85" height="19" />
      <rect x="852" y="138" rx="10" ry="10" width="85" height="19" />
      <rect x="1424" y="137" rx="10" ry="10" width="68" height="19" />
      <rect x="26" y="196" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="197" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="198" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="197" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="198" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="196" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="195" rx="10" ry="10" width="85" height="19" />
      <circle cx="1456" cy="203" r="12" />
      <rect x="26" y="258" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="259" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="260" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="259" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="260" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="258" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="257" rx="10" ry="10" width="85" height="19" />
      <circle cx="1456" cy="265" r="12" />
      <rect x="26" y="316" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="317" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="318" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="317" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="318" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="316" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="315" rx="10" ry="10" width="85" height="19" />
      <circle cx="1456" cy="323" r="12" />
      <rect x="26" y="379" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="380" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="381" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="380" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="381" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="379" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="378" rx="10" ry="10" width="85" height="19" />
      <circle cx="1456" cy="386" r="12" />
      <rect x="978" y="138" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="195" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="257" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="315" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="378" rx="10" ry="10" width="169" height="19" />
      <rect x="1183" y="139" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="196" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="258" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="316" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="379" rx="10" ry="10" width="85" height="19" />
      <rect x="1305" y="137" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="194" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="256" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="314" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="377" rx="10" ry="10" width="85" height="19" />
      <circle cx="37" cy="97" r="11" />
      <rect x="26" y="23" rx="5" ry="5" width="400" height="30" />
      <circle cx="1316" cy="88" r="11" />
      <rect x="1337" y="94" rx="0" ry="0" width="134" height="3" />
      <circle cx="77" cy="96" r="11" />
    </ContentLoader>
  );
};

const ChartingGrid = styled.div`
  margin-top: 40px;
  display: grid;
  //width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr)); //repeat(2, 1fr);
  grid-template-rows: auto;
  grid-row-gap: 80px;
  grid-column-gap: 40px;
  padding: 20px;
`;

const ChartWrapper = styled.div`
  height: 400px;
  padding-bottom: 30px;
  margin-bottom: 10px;
`;

const DataByDiseaseTableWrapper = styled.div`
  margin-top: 20px;
  height: calc(100% - 20px);

  .BaseTable {
    &__header-row {
      background-color: #6c2797;
      color: #ffffff;
      font-weight: 500;
    }

    &__row:nth-of-type(odd) {
      background-color: #f6f6f6 !important;
    }

    &__row-cell,
    &__header-cell {
      border: 1px solid #eeeeee;
      justify-content: center;
    }
  }
`;

const DataExpectedVsReceivedTableWrapper = styled.div`
  margin-top: 20px;
  height: calc(100% - 40px);

  .BaseTable {
    &__header-row {
      background-color: #6c2797;
      color: #ffffff;
      font-weight: 500;
    }

    &__row:nth-of-type(odd) {
      background-color: #f6f6f6 !important;
    }

    &__row-cell,
    &__header-cell {
      border: 1px solid #eeeeee;
      justify-content: center;
    }
  }
`;
