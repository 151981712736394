import * as actionTypes from "./actionTypes";
const defaultState = {
  loading: {
    summary: false,
    patient: false,
    disease: false,
    totals: false,
    clinicaldatareceived: false
  },
  loaded: {
    summary: false,
    patient: false,
    disease: false,
    totals: false,
    clinicaldatareceived: false
  },
  summary: {},
  clinicaldatareceived: [],
  patients: [],
  diseases: [],
  releases: [],
  totals: {
    Diseases: []
  }
};

export const reducer = (state = defaultState, action) => {
  let newState = {
    ...state
  };

  switch (action.type) {
    case actionTypes.SET_DATATYPE_LOADING: {
      newState.loading = {
        ...newState.loading,
        ...action.value
      };
      return newState;
    }
    case actionTypes.SET_DATATYPE_LOADED: {
      newState.loaded = {
        ...newState.loaded,
        ...action.value
      };
      return newState;
    }
    case actionTypes.SET_SUMMARY_DATA: {
      newState.summary = action.value;
      return newState;
    }
    case actionTypes.SET_TOTALS_DATA: {
      newState.totals = action.value;
      return newState;
    }
    case actionTypes.SET_PATIENT_DATA: {
      newState.patients = action.value;
      return newState;
    }
    case actionTypes.SET_DISEASE_DATA: {
      newState.diseases = action.value;
      return newState;
    }
    case actionTypes.SET_RELEASES_DATA: {
      newState.releases = action.value;
      return newState;
    }
    case actionTypes.SET_CLINICAL_DATA_RECEIVED: {
      newState.clinicaldatareceived = action.value;
      return newState;
    }
    default: {
      return state;
    }
  }
};
