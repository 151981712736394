import React from "react";

export const MolecularDataGenerationInfo = () => {
    return (
        <section className="info molecular-data-generation">
            <div className="content-container">
                <h2>Molecular Data Generation</h2>
                <p>
                    Once you are a part of the ORIEN Total Cancer Care
                    organization you are eligable to participate in Avatar.
                    ORIEN Avatar by M2Gen uses a next generation sequencing
                    vendor to generate molecular data for its members. M2Gen
                    covers the entire cost of the molecular data and returns it
                    to the ORIEN Members, providing them with:
                </p>
                <ul>
                    <li>Tumor Whole Exome sequencing</li>
                    <li>Tumor RNA Sequencing</li>
                    <li>Germline Whole Exome Sequencing</li>
                </ul>
            </div>
        </section>
    );
};
