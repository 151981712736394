import * as actionTypes from "./actionTypes";
const defaultState = {
  loading: {
    summary: false
  },
  loaded: {
    summary: false
  },
  patientGoal: {
    fiscalYear: "",
    patientGoal: "",
  },
  avatarPatientsPipeline: {},
  patientsOverTime: {
    AvatarReleased: { PatientsOverTime: [] },
    ClinReceived: { PatientsOverTime: [] },
    Extracted: { PatientsOverTime: [] },
    Shipped: { PatientsOverTime: [] }
  },
  weeklySpecimenShipments: [], // Total across network
  totalSpecimensShipped: " ", // 0, // Total across network
  weeklySpecimenShipmentsBySite: [] // Site specific
};

export const reducer = (state = defaultState, action) => {
  let newState = {
    ...state
  };

  switch (action.type) {
    case actionTypes.SET_DATATYPE_LOADING: {
      newState.loading = {
        ...newState.loading,
        ...action.value
      };
      return newState;
    }
    case actionTypes.SET_DATATYPE_LOADED: {
      newState.loaded = {
        ...newState.loaded,
        ...action.value
      };
      return newState;
    }
    case actionTypes.SET_DATA: {
      newState = {
        ...newState,
        ...action.value
      };
      return newState;
    }
    default: {
      return state;
    }
  }
};

/*
 *case actionTypes.SET_AVATAR_PATIENTS_PIPELINE_DATA: {
 *  newState.avatarpatientspipeline = action.value;
 *  return newState;
 *}
 *case actionTypes.SET_WEEKLY_SPECIMEN_SHIPMENTS: {
 *  newState.weeklyspecimenshipments = action.value;
 *  return newState;
 *}
 */

/*
 *case actionTypes.SET_TOTALS_DATA: {
 *  newState.totals = action.value;
 *  return newState;
 *}
 *case actionTypes.SET_PATIENT_DATA: {
 *  newState.patients = action.value;
 *  return newState;
 *}
 *case actionTypes.SET_DISEASE_DATA: {
 *  newState.diseases = action.value;
 *  return newState;
 *}
 *case actionTypes.SET_RELEASES_DATA: {
 *  newState.releases = action.value;
 *  return newState;
 *}
 *case actionTypes.SET_DASHBOARD_DATA_RECEIVED: {
 *  newState.clinicaldatareceived = action.value;
 *  return newState;
 *}
 */
