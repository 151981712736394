import * as actionTypes from "./actionTypes";

export const setDataTypeLoading = (dataType, loading) => {
  return {
    type: actionTypes.SET_DATATYPE_LOADING,
    value: { [dataType]: loading }
  };
};

export const setDataTypeLoaded = (dataType, loaded) => {
  return {
    type: actionTypes.SET_DATATYPE_LOADED,
    value: { [dataType]: loaded }
  };
};

export const setSummaryData = data => {
  return {
    type: actionTypes.SET_SUMMARY_DATA,
    value: data
  };
};

export const setTotalsData = data => {
  return {
    type: actionTypes.SET_TOTALS_DATA,
    value: data
  };
};

export const setPatientData = data => {
  return {
    type: actionTypes.SET_PATIENT_DATA,
    value: data
  };
};

export const setDiseaseData = data => {
  return {
    type: actionTypes.SET_DISEASE_DATA,
    value: data
  };
};

export const setReleasesData = data => {
  return {
    type: actionTypes.SET_RELEASES_DATA,
    value: data
  };
};

export const setClinicalDataReceivedData = data => {
  return {
    type: actionTypes.SET_CLINICAL_DATA_RECEIVED,
    value: data
  };
};
