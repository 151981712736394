export const SET_DATATYPE_LOADING = "SET_DASHBOARD_DATA_LOADING";
export const SET_DATATYPE_LOADED = "SET_DASHBOARD_DATA_LOADED";
export const SET_DATA = "SET_DASHBOARD_DATA";
//export const SET_SUMMARY_DATA = "SET_DASHBOARD_SUMMARY_DATA";
//export const SET_AVATAR_PATIENTS_PIPELINE_DATA = "SET_DASHBOARD_AVATAR_PATIENTS_PIPELINE_DATA";
//export const SET_WEEKLY_SPECIMEN_SHIPMENTS = "SET_DASHBOARD_WEEKLEY_SPECIMEN_SHIPMENTS";
//export const SET_TOTALS_DATA = "SET_DASHBOARD_TOTALS_DATA";
//export const SET_PATIENT_DATA = "SET_DASHBOARD_PATIENT_DATA";
//export const SET_DISEASE_DATA = "SET_DASHBOARD_DISEASE_DATA";
//export const SET_RELEASES_DATA = "SET_DASHBOARD_RELEASES_DATA";
//export const SET_DASHBOARD_DATA_RECEIVED = "SET_DASHBOARD_DATA_RECEIVED";
