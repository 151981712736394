/**
 * Feedback Form
 * @author JR Leonard
 */
import PropTypes from "prop-types";

import React, { useState } from "react";
import { useSelector } from "react-redux";

import "./style/feeback.scss";
//import ContactImg from "../assets/feedback.png";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSmile, faMeh, faFrownOpen } from "@fortawesome/free-regular-svg-icons";
import MailboxIcon from "./assets/undraw_mail_box_kd5i.svg";

import OrienAvatarAPI, { GENERIC_OA_API_KEY } from "../api";
import { useAnalytics } from "../../hooks";

const emojis = [
  { icon: faSmile, description: "Great" },
  { icon: faMeh, description: "Just Okay" },
  { icon: faFrownOpen, description: "Not So Good" }
];

const Feedback = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [shouldShow, setShouldShow] = useState(false);
  const [comments, setComments] = useState("");
  const [emojiSelectedIndex, setEmojiSelectedIndex] = useState(0);
  const user = useSelector(state => state.user);
  const { trackEvent } = useAnalytics();

  const handleSubmitFeedback = e => {
    e.preventDefault();

    setFormIsSubmitting();

    const { userName, fullName, company, userType } = user;

    const overallExperience = emojis[emojiSelectedIndex] ? emojis[emojiSelectedIndex].description : "N/A";

    OrienAvatarAPI.request({
      method: "post",
      url: `/feedback`,
      data: {
        overallExperience,
        comments,
        currentPage: window.location.href,
        userName,
        fullName,
        affiliation: company,
        userType
      },
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": GENERIC_OA_API_KEY
      }
    })
      .then(response => {
        trackEvent("Users", "Submit feedback succeeded", userName);
        setFormSubmitSucceeded();
      })
      .catch(error => {
        trackEvent("Users", "Submit feedback failed", userName);
        setFormSubmitFailed();
      });
  };

  const setFormIsSubmitting = () => {
    setIsSubmitting(true);
    setHasError(false);
    setHasSubmitted(false);
  };

  const setFormSubmitSucceeded = () => {
    setIsSubmitting(false);
    setHasError(false);
    setHasSubmitted(true);
  };

  const setFormSubmitFailed = () => {
    setIsSubmitting(false);
    setHasError(true);
    setHasSubmitted(false);
  };

  const resetToDefaults = () => {
    setIsSubmitting(false);
    setHasError(false);
    setHasSubmitted(false);
    setShouldShow(false);
    setComments("");
    setEmojiSelectedIndex(0);
  };

  const setShowFeedbackWindow = shouldIt => {
    setShouldShow(shouldIt);
  };

  const handleFeedbackChanged = e => {
    const {
      target: { value }
    } = e;
    setComments(value);
  };

  const handleEmojiChanged = index => {
    setEmojiSelectedIndex(index);
  };

  const renderSubmitting = () => {
    return (
      <div className="feedback-form">
        <div className="header">
          <span className="close-feedback-icon" title="Click here to close" onClick={() => setShowFeedbackWindow(false)}>
            X
          </span>
          <h2>Submitting Your Feedback...</h2>
        </div>
        <div className="section">
          <div className="loading-state-container">
            <svg className="loading-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
              <circle cx="170" cy="170" r="160" />
              <circle cx="170" cy="170" r="135" />
              <circle cx="170" cy="170" r="110" />
              <circle cx="170" cy="170" r="85" />
            </svg>
            <h3 className="loading-title">Submitting</h3>
            <p className="loading-description">Sending your feedback</p>
          </div>
        </div>
      </div>
    );
  };

  const renderSubmitted = () => {
    return (
      <div className="feedback-form">
        <div className="header">
          <span className="close-feedback-icon" title="Click here to close" onClick={() => setShowFeedbackWindow(false)}>
            X
          </span>
          <h2>Thanks for your feedback!</h2>
          <p>
            Thank you for your time,
            <br />
            we have received your feedback!
          </p>
        </div>
        <div className="section centered">
          <img className="success-icon" src={MailboxIcon} />
        </div>
        <div className="section centered">
          <input className="button" value="Close Window" onClick={() => resetToDefaults()} title="Click to close window" />
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <form className="feedback-form" onSubmit={handleSubmitFeedback}>
        <div className="header">
          <span className="close-feedback-icon" title="Click here to close" onClick={() => setShowFeedbackWindow(false)}>
            X
          </span>
          <h2>Send us your feedback!</h2>
          <p>
            Have a suggestion you'd like to share?
            <br />
            Let us know in the fields below.
          </p>
        </div>
        <div className="section">
          {hasError && (
            <div className="error">
              Oops, something went wrong.
              <br />
              Please try again!
            </div>
          )}
          <h3>How was your experience?</h3>
          <div className="emojis-container">
            {emojis.map((emoji, index) => (
              <div key={index} className="emoji-container" aria-label="How was your experience">
                <FontAwesomeIcon
                  key={index}
                  className={classnames("emoji", {
                    active: index === emojiSelectedIndex
                  })}
                  icon={emoji.icon}
                  onClick={() => handleEmojiChanged(index)}
                  title={emojis[index].description}
                />
              </div>
            ))}
          </div>
          <div className="emoji-description">{emojis[emojiSelectedIndex].description}</div>
        </div>
        <div className="section">
          <textarea className="comments" rows={5} value={comments} onChange={handleFeedbackChanged} placeholder="Describe your experience, providing as much detail as possible." aria-label="Comments" />
        </div>
        <div className="section submit">
          <input className="button" type="submit" value="Submit Feedback" onClick={handleSubmitFeedback} title="Click to submit your feedback" />
        </div>
      </form>
    );
  };

  return (
    <div role="form" aria-label="feedback">
      {!shouldShow && (
        <div id="feedback-slideout-handle" onClick={() => setShowFeedbackWindow(true)} title="Click here to submit feedback">
          FEEDBACK
        </div>
      )}
      <div id="feedback-slideout" className={classnames({ show: shouldShow })}>
        <div className={"feedback-form-container"}>
          {hasSubmitted && renderSubmitted()}
          {isSubmitting && renderSubmitting()}
          {!hasSubmitted && !isSubmitting && renderForm()}
        </div>
      </div>
    </div>
  );
};

export default Feedback;
