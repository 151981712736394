import React, { memo } from "react";
import PropTypes from "prop-types";
import BaseTable, { Column, AutoResizer } from "react-base-table";
import "react-base-table/styles.css";

export const ClinicalDataExpectedVsReceivedGrid = memo(function ClinicalDataExpectedVsReceivedGrid_Memo({ columns, data }) {
  return <AutoResizer>{({ width, height }) => <BaseTable width={width} height={height} columns={columns} data={data} />}</AutoResizer>;
});

ClinicalDataExpectedVsReceivedGrid.propTypes = {
  columns: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};
