import React, { memo } from "react";
import PropTypes from "prop-types";
import { ResponsiveLine } from "@nivo/line";

export const CurrentAvatarPatientCountLineChart = memo(function CurrentAvatarPatientCountLineChart_Memo({ data }) {
  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 40, right: 20, bottom: 110, left: 60 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: false,
        reverse: false
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -54,
        legend: "", // "Month & Year of Shipment",
        legendOffset: 70,
        legendPosition: "middle"
      }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "", //"Count of Patient Specimens Shipped",
        legendOffset: -55,
        legendPosition: "middle"
      }}
      colors={{ scheme: "nivo" }}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      enablePointLabel={false}
      pointLabel="y"
      pointLabelYOffset={-12}
      enableArea={false}
      enableGridX={false}
      useMesh={true}
      crosshairType="cross"
      curve="linear"
      legends={[
            {
                anchor: 'top',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: -30,
                itemsSpacing: 20,
                itemDirection: 'left-to-right',
                itemWidth: 150,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 14,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
    />
  );
});

CurrentAvatarPatientCountLineChart.propTypes = {
  data: PropTypes.object.isRequired
};

