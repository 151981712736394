import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const KPIs = ({ data }) => {
  const formatValue = value => {
    return value ? value : "-";
  };

  return (
    <Wrapper>
      {data.map((kpi, index) => {
        return (
          <KPIWrapper key={index}>
            <Title>{kpi.title}</Title>
            <Value color={kpi.color || null}>{formatValue(kpi.value)}</Value>
          </KPIWrapper>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  text-align: center;
`;

const KPIWrapper = styled.div`
  margin: 1rem 0;
`;

const Title = styled.div`
  font-size: 1.7rem;
`;

const Value = styled.div`
  font-size: 2.8rem;
  color: ${({ color }) => color || "#007cbb"};
`;

KPIs.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      color: PropTypes.string
    })
  )
};
