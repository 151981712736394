import React, { memo } from "react";
import styled from "styled-components";

export const Overview = memo(function Overview_Memo({ patientsWithShippedSpecimens, molecularExtractedSent, molecularSequenced, molecularPassedQC, molecularValidated, clinicalReceived, clinicalPassedQC, clinicalValidated, totalAvatarPatients }) {
  const colors = {
    patients: "#25205c",
    molecular: "#1a89c2",
    clinical: "#6c2797",
    avatar: "#35a093"
  };

  return (
    <Wrapper>
      <GroupWrapper color={colors.patients}>
        <GroupTitleWrapper>Patients with Shipped Specimens</GroupTitleWrapper>
        <KPIWrapper>
          <KPIValue>{patientsWithShippedSpecimens}</KPIValue>
        </KPIWrapper>
      </GroupWrapper>
      <MultiGroupWrapper>
        <GroupWrapper color={colors.molecular}>
          <GroupTitleWrapper>Molecular Overview</GroupTitleWrapper>
          <KPIWrapper>
            <KPITitle>Molecular Extracted/Sent</KPITitle>
            <KPIValue>{molecularExtractedSent}</KPIValue>
          </KPIWrapper>
          <KPIWrapper>
            <KPITitle>Molecular Sequenced</KPITitle>
            <KPIValue>{molecularSequenced}</KPIValue>
          </KPIWrapper>
          <KPIWrapper>
            <KPITitle>Molecular Passed QC</KPITitle>
            <KPIValue>{molecularPassedQC}</KPIValue>
          </KPIWrapper>
          <KPIWrapper>
            <KPITitle>Molecular Validated</KPITitle>
            <KPIValue>{molecularValidated}</KPIValue>
          </KPIWrapper>
        </GroupWrapper>
        <GroupWrapper color={colors.clinical}>
          <GroupTitleWrapper>Clinical Overview</GroupTitleWrapper>
          <KPIWrapper>
            <KPITitle>Clinical Received</KPITitle>
            <KPIValue>{clinicalReceived}</KPIValue>
          </KPIWrapper>
          <KPIWrapper>
            <KPITitle>Clinical Passed QC</KPITitle>
            <KPIValue>{clinicalPassedQC}</KPIValue>
          </KPIWrapper>
          <KPIWrapper>
            <KPITitle>Clinical Validated</KPITitle>
            <KPIValue>{clinicalValidated}</KPIValue>
          </KPIWrapper>
        </GroupWrapper>
      </MultiGroupWrapper>
      <GroupWrapper color={colors.avatar}>
        <GroupTitleWrapper>Total Avatar Patients</GroupTitleWrapper>
        <KPIWrapper>
          <KPIValue>{totalAvatarPatients}</KPIValue>
        </KPIWrapper>
      </GroupWrapper>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
`;
const MultiGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  margin-bottom: 1rem;
  text-align: center;
  color: ${({ color }) => color};

  & > div:nth-child(1) {
    padding: 0.3rem 0.3rem;
    background-color: ${({ color }) => color};
  }
`;

const GroupTitleWrapper = styled.div`
  color: #ffffff;
`;

const KPIWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border: 1px solid #eee;
`;

const KPITitle = styled.div`
  color: #888888;
`;

const KPIValue = styled.div`
  font-size: 2.8rem;
`;
