/**
 * Class This the Footer main app component for the portal
 * @author Gerardo Renovales <gerardo.renovales@m2gen.com>
 * @requires react
 */

//React components
import React from "react";
import { Link } from "react-router-dom";
import M2GenPoweredLogo from "./assets/M2GenPoweredLogo.svg";

import "./styles/Footer.scss";

const currentYear = new Date().getFullYear();

export default class Footer extends React.Component {
    render() {
        return (
            <footer className="Footer">
                <p className="noprint">
                    <span className="footer-links">
                        <Link to="/">Home</Link> |{" "}
                        <Link to="/agreement">Terms</Link> |{" "}
                        <Link to="/manuals">User Manuals</Link> |{" "}
                        <Link to="/releasenotes">Release Notes</Link> |{" "}
                        <Link to="/account">My Account</Link> 
                        {
                        /*
                         *    |{" "}
                         *<Link to="/faq">FAQ</Link>
                         */
                        }
                    </span>
                </p>
                <p>
                    <span className="footer-text">
                        &copy; {currentYear} M2GEN. All Rights Reserved.
                        Technical Issues:{" "}
                        <a href="mailto:support@m2gen.com">support@m2gen.com</a>
                    </span>
                </p>
                <div className="m2genpowered-container">
                    <img src={M2GenPoweredLogo} alt="powered by M2GEN Logo" />
                </div>
            </footer>
        );
    }
}
